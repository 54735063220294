<script setup lang="ts"></script>

<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 6.45703C9.50152 6.45703 10.7188 5.23981 10.7188 3.73828C10.7188 2.23676 9.50152 1.01953 8 1.01953C6.49848 1.01953 5.28125 2.23676 5.28125 3.73828C5.28125 5.23981 6.49848 6.45703 8 6.45703Z"
            fill="currentColor"
        />
        <path
            d="M13.5 6.457C14.4492 6.457 15.2188 5.68749 15.2188 4.73825C15.2188 3.78901 14.4492 3.0195 13.5 3.0195C12.5508 3.0195 11.7812 3.78901 11.7812 4.73825C11.7812 5.68749 12.5508 6.457 13.5 6.457Z"
            fill="currentColor"
        />
        <path
            d="M2.5 6.457C3.44924 6.457 4.21875 5.68749 4.21875 4.73825C4.21875 3.78901 3.44924 3.0195 2.5 3.0195C1.55076 3.0195 0.78125 3.78901 0.78125 4.73825C0.78125 5.68749 1.55076 6.457 2.5 6.457Z"
            fill="currentColor"
        />
        <path
            d="M4.19344 8.00047C3.51688 7.44616 2.90416 7.51953 2.12188 7.51953C0.951875 7.51953 0 8.46578 0 9.62859V13.0414C0 13.5464 0.412188 13.957 0.919062 13.957C3.10738 13.957 2.84375 13.9966 2.84375 13.8627C2.84375 11.4443 2.55731 9.67087 4.19344 8.00047Z"
            fill="currentColor"
        />
        <path
            d="M8.74406 7.532C7.37769 7.41803 6.19003 7.53331 5.16563 8.37888C3.45134 9.752 3.78125 11.6008 3.78125 13.8626C3.78125 14.461 4.26813 14.957 4.87563 14.957C11.4719 14.957 11.7345 15.1698 12.1256 14.3036C12.2539 14.0106 12.2188 14.1037 12.2188 11.3014C12.2188 9.07556 10.2915 7.532 8.74406 7.532Z"
            fill="currentColor"
        />
        <path
            d="M13.8781 7.5195C13.0916 7.5195 12.4822 7.44688 11.8066 8.00044C13.4305 9.65844 13.1562 11.3109 13.1562 13.8626C13.1562 13.9974 12.9374 13.957 15.0481 13.957C15.5731 13.957 16 13.5317 16 13.0089V9.62856C16 8.46575 15.0481 7.5195 13.8781 7.5195Z"
            fill="currentColor"
        />
    </svg>
</template>
